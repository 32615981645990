import React, { useState, useEffect } from "react";
import styles from "./buyTradingCards.module.scss";

const BuyTradingCards = () => {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 768px)")
      .addEventListener("change", (e) => setIsMobile(e.matches));
  }, []);

  return (
    <div className={styles.cover}>
      <p className={styles.capitals}>BUY TRADING CARDS</p>
      <p className={styles.title}>Pre-order Bitcoin Beasts Trading Cards.</p>
      <div className={styles.column}>
        {isMobile ? (
          <img
            src="/buy_trading_cards_sm.png"
            alt="World map"
            className={styles.image}
          />
        ) : (
          <img
            src="/buy_trading_cards.png"
            alt="World map"
            className={styles.image}
          />
        )}
        {isMobile && (
          <p className={styles.subtitle}>
            Pre-order a pack of 21 Bitcoin Beast Trading Cards each with unique
            Bitcoin-related learning specific every character from Tribe Clash.
            Cards are fun, engaging, and informative about entry-level Bitcoin
            topics.
          </p>
        )}
      </div>
      <button
        className={styles.navButton}
        onClick={() =>
          window.open("https://pay.zaprite.com/pl_uHP2yQxFag", "_blank")
        }
      >
        Pre-order Trading Cards
      </button>
    </div>
  );
};

export default BuyTradingCards;
