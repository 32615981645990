import React from "react";
import styles from "./gameRules.module.scss";

const GameRules = () => {
  return (
    <div className={styles.cover}>
      <div className={styles.row}>
        <div className={styles.container}></div>
        <div className={styles.column}>
          <p className={styles.capitals}>
            GAME RULES
          </p>
          <span className={styles.title}>
            1 City. 21 Territories. 1 Winner.
          </span>
        </div>
        <div className={styles.container}></div>
        <div className={styles.container}></div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerTwo}></div>
        <div className={styles.containerTwo}></div>
        <div className={styles.outerIconContainer}>
          <div className={styles.iconContainer}>
          <img src="/one.png" alt="One" className={styles.iconTwo} />
          </div>
          <p className={styles.subtitle}>
            <strong>Claim Territories</strong>
          </p>
          <span className={styles.subtitle}>
            Jippi selects a random real-world Territory in your city each week for your Tribe to claim.
          </span>
        </div>
        <div className={styles.outerIconContainer}>
          <div className={styles.iconContainer}>
          <img src="/two.png" alt="Two" className={styles.iconTwo} />
          </div>
          <p className={styles.subtitle}>
            <strong>Discover Beasts</strong>
          </p>
          <span className={styles.subtitle}>
            Explore the Territory with your phone. Walk around until you discover a digital Bitcoin Beast.
          </span>
        </div>
        <div className={styles.outerIconContainer}>
          <div className={styles.iconContainer}>
          <img src="/three.png" alt="Three" className={styles.iconTwo} />
          </div>
          <p className={styles.subtitle}>
            <strong>Catch Beasts</strong>
          </p>
          <span className={styles.subtitle}>
            Tap on the Bitcoin Beast to catch it. But watch out, competing Tribes can contest your catch!
          </span>
        </div>
        <div className={styles.containerTwo}></div>
        <div className={styles.containerTwo}></div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerTwo}></div>
        <div className={styles.containerTwo}></div>
        <div className={styles.outerIconContainer}>
          <div className={styles.iconContainer}>
          <img src="/four.png" alt="Four" className={styles.iconTwo} />
          </div>
          <p className={styles.subtitle}>
            <strong>Tribe Clash</strong>
          </p>
          <span className={styles.subtitle}>
            If a catch is contested, players will enter into a knockout quiz battle until a final player remains.
          </span>
        </div>
        <div className={styles.outerIconContainer}>
          <div className={styles.iconContainer}>
          <img src="/five.png" alt="Five" className={styles.iconTwo} />
          </div>
          <p className={styles.subtitle}>
            <strong>Be Quick</strong>
          </p>
          <span className={styles.subtitle}>
            The player who answers all quiz questions correctly the fastest while in the Territory catches the Bitcoin Beast.
          </span>
        </div>
        <div className={styles.outerIconContainer}>
          <div className={styles.iconContainer}>
          <img src="/six.png" alt="Six" className={styles.iconTwo} />
          </div>
          <p className={styles.subtitle}>
            <strong>Win 0.5 BTC</strong>
          </p>
          <span className={styles.subtitle}>
            The Tribe with the most Territories at the end of the game wins half a Bitcoin to split equally with all Tribe members.
          </span>
        </div>
        <div className={styles.containerTwo}></div>
        <div className={styles.containerTwo}></div>
      </div>
    </div>
  );
};

export default GameRules;
