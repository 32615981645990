import React from "react";
import Nav from "../Nav";
import Headline from "./Headline";
import HowItWorks from "./HowItWorks";
import GameRules from "./GameRules";
import BuyBeasts from "./BuyBeasts";
import Footer from "../Footer";
import MeetBeasts from "./MeetBeasts";
import ComingSoon from "./ComingSoon";
import BuyTradingCards from "./BuyTradingCards";

const ForTheMasses = () => {
  return (
    <>
      <Nav buttonText="Register" />
      <Headline />
      <HowItWorks />
      <GameRules />
      <MeetBeasts />
      <ComingSoon />
      <BuyBeasts />
      <BuyTradingCards />
      <Footer />
    </>
  );
};

export default ForTheMasses;
