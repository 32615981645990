import React from "react";
import styles from "./headline.module.scss";

const Headline = () => {
  return (
    <div className={styles.cover}>
      <div className={styles.column}>
        <div className={styles.row}>
          <img
            src="/tribe_clash_icon.png"
            alt="Tribe Clash logo"
            className={styles.logo}
          />
          <div className={styles.jippiLogo} />
        </div>
        <p className={styles.title}>
          Win $30k in BTC
          <br />
          with Tribe Clash.
        </p>
        <p className={styles.subtitle}>
          A competitive Bitcoin education
          <br />
          game. It's like Pokémon GO for
          <br />
          Bitcoin.
        </p>
      </div>
      <div className={styles.container}>
        <img
          src="/tribe_clash_app.png"
          alt="iPhone with Jippi App"
          className={styles.image}
        />
      </div>
    </div>
  );
};

export default Headline;
